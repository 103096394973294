var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roles nj-grid"},[_c('nj-question-dialog',{ref:"removeQuestionDialog",attrs:{"title":"Excluir Agendamento de Tarefas","text":"Deseja realmente excluir o agendamento e tarefas?"}}),_c('div',{staticClass:"header"},[_c('span',{staticClass:"title"},[_vm._v("Listagem de Agendamento de Tarefas")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"append-icon":"mdi-magnify","placeholder":"Pesquisar","outlined":"","autofocus":"","hide-details":"","dense":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-btn',{attrs:{"small":"","color":"primary","title":"Novo Agendamento de Tarefas","elevation":"0","to":"/task_schedulers/new"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Novo ")],1)],1),_c('v-data-table',{ref:"taskSchedulerDataTable",staticClass:"nj-table",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"loading-text":"Buscando...","hide-default-footer":"","disable-pagination":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("by")(item.by))+" ")]}},{key:"item.task",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("task")(item.task))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("njDateFormat")(item.updated_at,null, 'DD/MM/YYYY HH:mm'))+" ")]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"160px"}},[_c('v-btn',{attrs:{"to":{ name: 'TaskSchedulerShow', params: { id: item.id } },"title":"Visualizar","icon":""}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"to":{ name: 'TaskSchedulerEdit', params: { id: item.id } },"title":"Editar","icon":""}},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-square-edit-outline")])],1),_c('v-btn',{attrs:{"title":"Remover","icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }