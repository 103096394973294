<template>
  <div class="roles nj-grid">
    <nj-question-dialog
      ref="removeQuestionDialog"
      title="Excluir Agendamento de Tarefas"
      text="Deseja realmente excluir o agendamento e tarefas?"
    />
    <div class="header">
      <span class="title">Listagem de Agendamento de Tarefas</span>
      <v-form @submit.prevent="search()">
        <v-text-field
          append-icon="mdi-magnify"
          placeholder="Pesquisar"
          outlined
          autofocus
          hide-details
          dense
          class="ma-0 pa-0"
          v-model="searchTerm"
        >
        </v-text-field>
      </v-form>
      <v-btn
        small
        color="primary"
        title="Novo Agendamento de Tarefas"
        elevation="0"
        to="/task_schedulers/new"
      >
        <v-icon left> mdi-plus </v-icon>
        Novo
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="nj-table"
      :loading="loading"
      :options.sync="options"
      loading-text="Buscando..."
      ref="taskSchedulerDataTable"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.by="{ item }">
        {{ item.by | by }}
      </template>
      <template v-slot:item.task="{ item }">
        {{ item.task | task }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | njDateFormat(null, 'DD/MM/YYYY HH:mm') }}
      </template>
      <template v-slot:item.controls="{ item }">
        <div style="width: 160px">
          <v-btn
            :to="{ name: 'TaskSchedulerShow', params: { id: item.id } }"
            title="Visualizar"
            icon
          >
            <v-icon color="green">mdi-eye</v-icon>
          </v-btn>
          <v-btn
            :to="{ name: 'TaskSchedulerEdit', params: { id: item.id } }"
            title="Editar"
            icon
          >
            <v-icon color="orange">mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn @click="remove(item)" title="Remover" icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import TaskSchedulerEnum from '@/enumerations/task_scheduler_enum'

export default {
  name: 'taskSchedulers',
  data: () => ({
    searchTerm: '',
    loading: false,
    items: [],
    operations: [],
    options: {},
    headers: [
      { text: 'Descrição', value: 'description' },
      { text: 'Última modificação', value: 'updated_at' },
      { text: 'A cada', value: 'by' },
      { text: 'Intervalo', value: 'interval' },
      { text: 'Tarefa', value: 'task' },
      { text: 'Ações', value: 'controls', sortable: false },
    ],
  }),

  filters: {
    by: function (value) {
      return TaskSchedulerEnum.by.text(value)
    },

    task: function (value) {
      return TaskSchedulerEnum.task.text(value)
    },
  },

  mounted() {
    this.search()
  },

  methods: {
    remove(item) {
      if (!this.$hasPermission('naja_app_roles_destroy')) {
        alert('Acesso negado')
        return
      }

      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.taskSchedulers
            .destroy(item.id)
            .then(async () => {
              // this.$toast.show('Agendamento de Tarefa removido com sucesso', 'success');
              this.search()
            })
            .catch(error => {
              if (error.response) {
                // this.$toast.show('Erro ao remover usuário', 'error');
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    search() {
      this.loading = true

      const query = {
        q: {
          s: ['created_at desc'],
          description_cont: this.searchTerm,
        },
      }

      Api.taskSchedulers
        .search(query)
        .then(response => {
          this.items = response.data.task_schedulers
        })
        .catch(error => {
          throw error
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>
