import { orderBy } from 'lodash'

const by = [
  { value: 'hour', text: 'Hora' },
  { value: 'minute', text: 'Minuto' },
  { value: 'day', text: 'Dia' },
  { value: 'week', text: 'Semana' },
  { value: 'month', text: 'Mês' },
  { value: 'sunday', text: 'Domingo' },
  { value: 'monday', text: 'Segunda' },
  { value: 'tuesday', text: 'Terça' },
  { value: 'wednesday', text: 'Quarta' },
  { value: 'thursday', text: 'Quinta' },
  { value: 'friday', text: 'Sexta' },
  { value: 'saturday', text: 'Sábado' },
]

const task = [
  { value: 'notifications', text: 'Notificações' },
  { value: 'schedule_confirmation', text: 'Confirmação de agendamento' },
  { value: 'teleatendimento', text: 'Sincronização de teleatendimento' },
]

export default {
  by: {
    text: search => by.find(x => x.value === search)?.text || '',
    all: orderBy(by, [item => item.text]),
  },
  task: {
    text: search => task.find(x => x.value === search)?.text || '',
    all: orderBy(task, [item => item.text]),
  },
}
